// API Base URL
// export const BASE_URL = 'http:localhost:8000';
//export const BASE_URL = 'http://localhost:8000';
//export const BASE_URL_WEBSOCKET = 'ws://localhost:8081';
//export const BASE_URL_CRONE = 'http://127.0.0.1:8000';
export const BASE_URL = '/api/'//'http://localhost:8000';
export const BASE_URL_WEBSOCKET = '/ws/' //ws://localhost:8081';

// Project IDs
export const PROJECT_IDS = ['BTC', 'ETH', 'SOL', 'BNB', 'WIF'];

// API Endpoints
export const API = {
  MODEL_STATE: `${BASE_URL}/model_state`,
};

export const HEATMAP_INITIAL = {
  BTC: { z_score: 0, residual: 0 },
  ETH: { z_score: 0, residual: 0 },
  SOL: { z_score: 0, residual: 0 },
  WIF: { z_score: 0, residual: 0 },
  BNB: { z_score: 0, residual: 0 },
};

export const PH_GENERAL_OVERVIEW = Array.from({ length: 9 }, () => ({
  label: '--',
  value: '0%',
  trend: 'N/A',
}));
// Default selected option
export const DEFAULT_SELECTED_OPTION = 'BTC';

// Custom color scale for heatmap
export const CUSTOM_HEATMAP_COLORS = [
  [0, 'rgba(255,99,71,0.8)'],
  [0.1, 'rgba(255,140,0,0.8)'],
  [0.2, 'rgba(255,165,0,0.8)'],
  [0.3, 'rgba(255,215,0,0.8)'],
  [0.4, 'rgba(255,255,0,0.8)'],
  [0.5, 'rgba(255,255,102,0.8)'],
  [0.6, 'rgba(173,255,47,0.8)'],
  [0.7, 'rgba(144,238,144,0.8)'],
  [0.8, 'rgba(50,205,50,0.8)'],
  [0.9, 'rgba(34,139,34,0.8)'],
  [1, 'rgba(0,128,0,0.8)'],
];

export const MARKET_OVERVIEW_INIT = [
  {
    rank: '--',
    symbol: '--',
    currentPrice: '--',
    predictedPrice: '--',
    residual: '--',
  },
  {
    rank: '--',
    symbol: '--',
    currentPrice: '--',
    predictedPrice: '--',
    residual: '--',
  },
  {
    rank: '--',
    symbol: '--',
    currentPrice: '--',
    predictedPrice: '--',
    residual: '--',
  },
];

export const MARKET_OVERVIEW_HOME = [
  { key: 'rank', label: 'Rank', sortable: true },
  { key: 'symbol', label: 'Symbol', sortable: true },
  { key: 'currentPrice', label: 'Current Price', sortable: false },
  { key: 'predictedPrice', label: 'Predicted Price', sortable: false },
  { key: 'residual', label: 'Residual %', sortable: true },
];

export const MARKET_OVERVIEW_MODEL = [
  { key: 'collectionName', label: 'Model Name', sortable: false },
  { key: 'residual', label: 'Residual', sortable: true },
  { key: 'r2_train', label: 'R2 Train', sortable: true },
  { key: 'mse_train', label: 'MSE Train', sortable: true },
];

export const MODEL_OVERVIEW = [
  { key: 'symbol', label: 'Symbol', sortable: false },
  { key: 'residual', label: 'Residual', sortable: true },
  { key: 'r2_train', label: 'R2 Train', sortable: true },
  { key: 'mse_train', label: 'MSE Train', sortable: true },
];

export const STRATEGIES_MODEL = [
  { key: 'collectionName', label: 'Model Name', sortable: false },

  { key: 'sharpe', label: 'Sharpe', sortable: true },
  { key: 'sortino', label: 'Sortino', sortable: true },
  { key: 'winrate', label: 'Win Rate', sortable: true },
];

export const TRADES_STRATEGY = [
  { key: 'timestamp', label: 'Time', sortable: true },
  { key: 'price', label: 'Price', sortable: false },
  { key: 'position_change', label: 'Position Change', sortable: false },
  { key: 'side', label: 'Side', sortable: false },
];

export const tokenAddresses = {
  Y: '0x477a3d269266994F15E9C43A8D9C0561C4928088',
};

export const MinTokenAmount =10;
export const MinTokenAmountLayout = 50000;


export const EXTERNAL_LINKS = {
  X: 'https://x.com/yai_erc',
  Telegram: 'https://t.me/+BAgu_j2177kxMDZk',
  Docs: 'https://docs.yoracle.ai/',
};
export const Fixed_Amount_Upto_Below_Threshold = 3
export const Fixed_Amount_Upto_Above_Threshold = 2
export const Fixed_Amount_threshold= 10

export const AMOUNT_NEEDED_FOR_TEMPORARY_SUBSCRIPTION = 4000

export const SUBSCRIPTION_ADDRESS = "0xE1deE4371addc2E07e24D8cB875b3d64d0aaEA98"
export const SUBSCRIPTION_AMOUNT =  "4000"
export const PERMANENT_AMOUNT = "50000"

