import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { React, useContext, useEffect, memo } from 'react';
import { AuthContext } from '../AuthWrapper/AuthWrapper.js';
import GeneralOverview from '../components/GeneralOverview/GeneralOverview';
import withAuthenticationGuard from '../components/guards/withAuthWrapper';
import StateHeatmap from '../components/Heatmap/StateHeatmap';
import MarketOverview from '../components/MarketOverview/MarketOverview';
import Technicals from '../components/Technicals/Technicals';
import withLayout from '../components/withLayout/withLayout';
import { MinTokenAmount } from '../constants/constants';
import { useWalletContext } from '../context/WalletContext.js';
import Loader from '../components/Loader/Loader';

const Home = (props) => {
  const { tokenBalance } = useWalletContext();
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  const isLoading = state.matches('loadingDashboardState') || state.matches('loadingHomeRouteData');
  console.log(tokenBalance)
  console.log(isConnected)
  console.log(state)
  console.log(send)
  useEffect(() => {
    if (state.matches('model') || state.matches('home') || state.matches('strategy') || state.matches('apiKey')) send({ type: 'CHECK_ROUTE' });
  }, [send]);

  const RenderLoadingOverlay = () => (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(1px)',
        pointerEvents: 'none',
        zIndex: 10,
        top: '0',
        left: '0',
      }}
    />
  );

  const RenderLoader = memo(() => <Loader />);

  const renderComponents = (data) => (
    <>
      <GeneralOverview dashboardState={data} />
      <Technicals dashboardState={data} />
      <MarketOverview dashboardState={data} />
      <StateHeatmap dashboardState={data} />
    </>
  );

  return (
    <>
      {isConnected && tokenBalance >= MinTokenAmount ? (
        <>
          {renderComponents(state.context.dashboardState)}
          {isLoading && <RenderLoadingOverlay />}
          {isLoading && <RenderLoader />}
        </>
      ) : (
        <>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backdropFilter: 'blur(1px)',
              pointerEvents: 'none',
              zIndex: '10',
              top: '0',
              left: '0',
            }}></div>
          {renderComponents(null)}
        </>
      )}
    </>
  );
};

export default withAuthenticationGuard(Home);
